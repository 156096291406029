import * as React from "react";
import type { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      stroke="#D4D4D8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m13 13-2.867-2.867m1.534-3.8A5.333 5.333 0 1 1 1 6.333a5.333 5.333 0 0 1 10.667 0"
    />
  </svg>
);
export default SvgSearch;
